.inputField {
  border-radius: 6px;
  border: 1px solid var(--slate-300, #cbd5e1);
  background: var(--white-100, #fff);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  align-self: stretch;
}

.inputField:focus {
  border: 1px solid var(--slate-300, #cbd5e1);
}

.inputFieldInvalid {
  border: 1px solid var(--red-300, #f56565);
  background: var(--red-100, #fee2e2);
}

.inputFieldInvalid:focus {
  border: 1px solid var(--red-300, #f56565);
  background: var(--red-100, #fee2e2);
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
