.default {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.compact {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
}

.mini {
  display: flex;
  padding: var(--Spacing-8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4, 4px);
  align-self: stretch;

  border-radius: 6px;
}

.icon {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4, 4px);
  align-self: stretch;

  border-radius: 6px;
}

.primary {
  background: var(--slate-900, #0f172a);
  color: var(--white, var(--white-100, #fff));
}

.primary:hover {
  background: var(--slate-700, #334155);
  color: var(--white, var(--white-100, #fff));
}

.destructive {
  background: var(--red-500, #ef4444);
  color: var(--white, var(--white-100, #fff));
}

.destructive:hover {
  background: var(--red-600, #dc2626);
  color: var(--white, var(--white-100, #fff));
}

.outline {
  border: 1px solid var(--slate-300, #cbd5e1);
  background: var(--white-100, #fff);
  color: var(--slate-900, #0f172a);
}

.outline:hover {
  border: 1px solid var(--slate-300, #cbd5e1);
  background: var(--slate-100, #f1f5f9);
  color: var(--slate-900, #0f172a);
}
