.container {
  display: flex;
  width: 450px;
  padding: var(--Spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: var(--Radius-8, 8px);
  border: 1px solid var(--slate-300, #cbd5e1);
  background: var(--white-100, #fff);
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.headerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);
  flex: 1 0 0;
  color: var(--slate-900, #0f172a);
  font-weight: 600;
}
