.content {
  width: auto;
  color: var(--slate-900, #0f172a);

  padding: 4px 6px;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
