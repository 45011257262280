.logoContainer {
  display: flex;
  padding: 2.86px;
  justify-content: center;
  align-items: center;
}

.play,
.filter,
.globe,
.strikethrough,
.webhook {
  border-radius: 2.86px;
}

.globe,
.strikethrough,
.webhook {
  background: var(--indigo-500, #6366f1);
}

.play,
.filter {
  background: var(--teal-500, #14b8a6);
}
