.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inputFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.buttonsFrame {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--Spacing-8, 8px);
  align-self: stretch;
}

.button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--slate-900, #0f172a);
}
