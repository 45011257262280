.container {
  min-width: 450px;
  gap: 32px;
  border-radius: var(--Radius-8, 8px);
  border: 1px solid #cbd5e1;
  background: var(--white-100, #fff);
}

.formContainer {
  color: #0f172a;
}
