.container {
  display: flex;
  width: 450px;
  padding: var(--Spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.searchbox {
  color: var(--slate-900, #0f172a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
}

.alias {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.command {
  display: flex;
  height: 305px;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-8, 8px);
  align-self: stretch;
  overflow-y: hidden;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  height: 100%;
}

.innerFrame {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.appList {
  padding-bottom: 2.4em;
}
